var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCash100)+" ")]),_c('span',{staticClass:"me-3"},[_vm._v(" Extrato de pagamentos")]),_c('v-spacer')],1),(this.showTable)?_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":this.empreendimentosresumo,"item-key":"codigoObra","hide-default-footer":"","disable-sort":"","fixed-header":"","no-data-text":"Nenhum extrato encontrado","no-results-text":"Nenhum extrato encontrado"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiOfficeBuildingMarkerOutline)+" ")]),_c('div',{staticClass:"d-flex flex-column"},[_c('small',[_vm._v(_vm._s(item.post))])])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.valor)+" ")])]}},{key:"item.valorPago",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","outlined":"","color":_vm.statusColor['green']}},[_vm._v(" "+_vm._s(item.valorPago)+" ")])]}},{key:"item.totalparcelas",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor['warning']}},[_vm._v(" "+_vm._s(item.totalparcelas)+" ")])]}},{key:"item.valorTotal",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","outlined":"","color":_vm.statusColor['warning']}},[_vm._v(" "+_vm._s(item.parcelasportipo[0].totalparcelaPaga)+" ")])]}},{key:"item.valorAPagar",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","outlined":"","color":_vm.statusColor['primary']}},[_vm._v(" "+_vm._s(item.parcelasportipo[0].totalparcelaApagar)+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"btn-bol text-center",staticStyle:{"background-color":"red","height":"20px !important","width":"90px !important"},attrs:{"small":"","icon":"","color":"white"},on:{"click":function($event){return _vm.gerarPDFDemonstrativoPgto(item.codigoEmpresa, item.numeroVenda, item.codigoObra)}}},[_vm._v(" Gerar PDF ")])],1)]}},{key:"item.parcelas",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-bol text-center",staticStyle:{"height":"20px !important","width":"90px !important"},attrs:{"small":"","icon":"","color":"white"},on:{"click":function($event){return _vm.verParcelas(item.codigoEmpresa, item.numeroVenda, item.codigoObra, item.empreendimento, item.identificador)}}},[_c('small',[_vm._v("Parcelas")]),_vm._v(" "),_c('small',{staticStyle:{"visibility":"hidden","position":"absolute"}},[_vm._v(_vm._s(item.parcelas))])])]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }