<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-row class="match-height">

        <v-col cols="12" sm="3">
          <vue-element-loading :active="valoresPagosLoading" spinner="mini-spinner" />
          <statistics-card-vertical :change="valorespagos.change" :color="valorespagos.color" :icon="valorespagos.icon"
            :statistics="valorespagos.statistics" :stat-title="valorespagos.statTitle" :subtitle="valorespagos.subtitle"
            :link="valorespagos.link" :mostrarInfo="valorespagos.mostrarInfo"
            :info="valorespagos.info"></statistics-card-vertical>
        </v-col>

        <v-col cols="12" sm="3">
          <vue-element-loading :active="saldoDevedorLoading" spinner="mini-spinner" />
          <statistics-card-vertical :change="saldodevedor.change" :color="saldodevedor.color" :icon="saldodevedor.icon"
            :statistics="saldodevedor.statistics" :stat-title="saldodevedor.statTitle" :subtitle="saldodevedor.subtitle"
            :link="saldodevedor.link" :mostrarInfo="saldodevedor.mostrarInfo"
            :info="saldodevedor.info"></statistics-card-vertical>
        </v-col>

        <v-col cols="12" sm="3">
          <vue-element-loading :active="MeusImoveisLoading" spinner="mini-spinner" />
          <statistics-card-vertical :change="totalemprendimentos.change" :color="totalemprendimentos.color"
            :icon="totalemprendimentos.icon" :statistics="totalemprendimentos.statistics"
            :stat-title="totalemprendimentos.statTitle" :subtitle="totalemprendimentos.subtitle"
            :link="totalemprendimentos.link"></statistics-card-vertical>
        </v-col>

        <v-col cols="12" sm="3">
          <vue-element-loading :active="ObrasLoading" spinner="mini-spinner" />
          <statistics-card-vertical :change="obras.change" :color="obras.color" :icon="obras.icon"
            :statistics="obras.statistics" :stat-title="obras.statTitle" :subtitle="obras.subtitle"
            :link="obras.link"></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <vue-element-loading :active="BoletosLoading" spinner="mini-spinner" />
      <dashboard-boletos v-if="this.listaBolCarregou" :boletos="this.boletosreimpressao"
        :boletosNaoVencidos="this.boletosNaoVencidos"></dashboard-boletos>
    </v-col>
    <v-col cols="12" md="12">
      <vue-element-loading :active="ObrasTableLoading" spinner="mini-spinner" />
      <dashboard-card-lista-obras v-if="this.listaObrasCarregou" :empreendiment="empreendimentos"
        :obr="obr"></dashboard-card-lista-obras>
    </v-col>
    <whatsapp></whatsapp>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCashCheck, mdiCashMinus, mdiOfficeBuilding, mdiAccountHardHat } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import DashboardCardListaObras from './DashboardCardListaObras.vue'
import DashboardBoletos from './DashboardBoletos.vue'

import UsuarioStore from './../../store/Usuario'

import Strings from './../../common/strings'

import moment from 'moment'
import Cookie from 'js-cookie'
import Whatsapp from '@/components/statistics-card/Whatsapp.vue'

export default {
  components: {
    StatisticsCardVertical,
    DashboardCardListaObras,
    DashboardBoletos,
    Whatsapp
  },
  mounted: function () {
    this.momentF = moment

    var unidades = []

    UsuarioStore.empreendimentos(Strings.imobiliariaId).then(result => {

      result.data[0].MyTable.forEach((value, index) => {

        if (Number.isInteger(value.Cod_pes)) {

          var unidade = []
          unidade[0] = value.Obra_CVen
          unidade[1] = `${value.Empresa_cven}`
          unidades.push(unidade)

          var fObras = {
            id: index,
            img: require('@/assets/images/logos/aviato.png?id=1'),
            title: value.Descr_obr,
            subtitle: value.Desc_emp,
            amount: '',
          }

          this.obr.push(fObras)
        }
      })

      UsuarioStore.unidades(unidades, Strings.imobiliariaId).then(result => {
        var count = 1
        result.data[0].forEach((value, index) => {

          value.forEach((val, ind) => {
            console.log(val)
            if (Number.isInteger(val.Num_CVen)) {
              var fUnidades = {
                id: count += 1,
                img: require('@/assets/images/logos/gumroad.png'),
                title: val.Identificador_unid,
                subtitle: val.Descr_obr,
                amount: 'visualizar',
                num_venda: val.Num_CVen,
                obra: val.Obra_CVen,
                empresa: val.Empresa_cven
              }

              this.empreendimentos.push(fUnidades)

              // Prepara um array de obras para obter boletos

              var payloadBol = {
                num_venda: val.Num_CVen,
                obra: val.Obra_CVen,
                empresa: val.Empresa_cven,
                naomostraboleto_vencido: false
              }

              var payloadEmpreendimentos = {
                num_venda: val.Num_CVen,
                obra: val.Obra_CVen,
                desc_obra: val.Descr_obr,
                identificador: val.Identificador_unid,
                empresa: val.Empresa_cven
              }

              this.payloadBoletosReimpressao.push(payloadBol)
              this.payloadEmpreendimentosExtrado.push(payloadEmpreendimentos)
            }
          })
        })

        Cookie.set(Strings.payloadEmpreendimentos, JSON.stringify(this.payloadEmpreendimentosExtrado))

        this.obras.statistics = `${this.obr.length}`
        this.totalemprendimentos.statistics = `${this.empreendimentos.length}`

        this.MeusImoveisLoading = false
        this.ObrasTableLoading = false
        this.ObrasLoading = false
        this.listaObrasCarregou = true

      }).catch(error => {
        if (error.response.status === 401) {
          Cookie.remove(Strings.cookie_token)
          Cookie.remove(Strings.cookie_username)
          this.$router.push('/pages/login')
        }

        this.MeusImoveisLoading = false
        this.ObrasTableLoading = false
        this.ObrasLoading = false
        console.log(error.response)
      })

    }).catch(error => {
      if (error.response.status === 401) {
        Cookie.remove(Strings.cookie_token)
        Cookie.remove(Strings.cookie_username)
        this.$router.push('/pages/login')
      }
      console.log(error.response)
    })

    UsuarioStore.extratoResumido(Strings.imobiliariaId).then(result => {

      if (result.data.length > 0) {
        this.valorespagos.statistics = `${result.data[0].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}`

        this.saldodevedor.statistics = `${result.data[1].toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}`
      }

      this.valoresPagosLoading = false
      this.saldoDevedorLoading = false

    }).catch(error => {
      if (error.response.status === 401) {
        Cookie.remove(Strings.cookie_token)
        Cookie.remove(Strings.cookie_username)
        this.$router.push('/pages/login')
      }
      console.log(error.response)

      this.saldoDevedorLoading = false
      this.valoresPagosLoading = false
    })

    UsuarioStore.boletosVencidos(Strings.imobiliariaId).then(result => {

      result.data.forEach((v, idx) => {

        let tipStatus = 1
        if (moment(v.dataVencimento).isBefore()) {
          tipStatus = 2
        }

        var fBoletos = {
          index: idx,
          responsive_id: v.nossoNumero,
          id: v.nossoNumero,
          vencimento: moment(String(v.dataVencimento)).format('DD/MM/YYYY'),
          obra: v.descricaoObra,
          empreendimento: v.descricaoEmpresa,
          identificador: '',
          valor: parseFloat(v.valorDocumento),
          status: tipStatus,
          acoes: v,
        }

        if (moment(v.dataVencimento).isAfter()) {
          this.boletosNaoVencidos.push(fBoletos)
        }

        this.boletosreimpressao.push(fBoletos)
      })

      this.BoletosLoading = false
      this.listaBolCarregou = true
    }).catch(error => {
      this.BoletosLoading = false
      if (error.response.status === 401) {
        Cookie.remove(Strings.cookie_token)
        Cookie.remove(Strings.cookie_username)
        this.$router.push('/pages/login')
      }


      console.log(error.response)
    }).finally(() => {
      this.$loading(false)
    })
  },
  data() {
    return {
      valoresPagosLoading: true,
      saldoDevedorLoading: true,
      ObrasLoading: true,
      MeusImoveisLoading: true,
      BoletosLoading: true,
      ObrasTableLoading: true,
    }
  },
  setup() {
    const obr = []
    const empreendimentos = []
    const payloadBoletosReimpressao = []
    const boletosNaoVencidos = []
    const payloadEmpreendimentosExtrado = []
    const boletosreimpressao = []
    const listaObrasCarregou = false
    const listaBolCarregou = false

    const valorespagos = {
      statTitle: 'Valores Pagos',
      icon: mdiCashCheck,
      color: 'success',
      subtitle: 'Ver Extrato',
      statistics: 'R$ -',
      change: '',
      link: '/pagamentos',
      mostrarInfo: true,
      info: '* sujeito a alteração'
    }

    const saldodevedor = {
      statTitle: 'Saldo Devedor',
      icon: mdiCashMinus,
      color: 'red',
      subtitle: 'Ver Extrato',
      statistics: 'R$ -',
      change: '',
      link: '/pagamentos',
      mostrarInfo: true,
      info: '* sujeito a alteração'
    }

    const obras = {
      statTitle: 'Empreendimentos',
      icon: mdiAccountHardHat,
      color: 'orange',
      subtitle: '',
      statistics: '-',
      change: '',
      link: '/'
    }

    const totalemprendimentos = {
      statTitle: 'Meus Imóveis',
      icon: mdiOfficeBuilding,
      color: 'blue',
      subtitle: 'Visualizar',
      statistics: '-',
      change: '',
      link: '/empreendimento'
    }

    return {
      valorespagos,
      saldodevedor,
      obras,
      totalemprendimentos,
      empreendimentos,
      obr,
      listaObrasCarregou,
      payloadBoletosReimpressao,
      boletosreimpressao,
      boletosNaoVencidos,
      listaBolCarregou,
      payloadEmpreendimentosExtrado
    }
  },
}
</script>
